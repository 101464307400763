export const forbiddenKeys = [
  'Tab',
  'Enter',
  'Shift',
  'Control',
  'Alt',
  'CapsLock',
  'Escape',
  'ArrowLeft',
  'ArrowUp',
  'ArrowRight',
  'ArrowDown',
  'Meta',
  'ContextMenu',
  'F1',
  'F2',
  'F3',
  'F4',
  'F5',
  'F6',
  'F7',
  'F8',
  'F9',
  'F10',
  'F11',
  'F12',
  'Insert',
  'Delete',
]
