export const accountStats = {
  0: {
    key: 'testsTaken',
    name: 'Tests Taken',
  },
  1: {
    key: 'timeTyping',
    name: 'Time Typing',
  },
  2: {
    key: 'startingWPM',
    name: 'Starting WPM',
  },
  3: {
    key: 'currentWPM',
    name: 'Current WPM',
  },
}
